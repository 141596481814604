import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'
import {siteUrl} from '../lib/config'

function SEO ({canonical, description, lang, meta, keywords, title, image, type = 'page', ldJson = null, amp = null}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || (data.site && data.site.description) || ''
        const siteTitle = (data.site && data.site.title) || ''
        const siteAuthor = (data.site && data.site.author && data.site.author.name) || ''
        const metaImage = (image && image.asset) ? image.asset.url : ''

        let jsonLD = [
        ]

        if (ldJson) {
          ldJson[1].publisher.logo = {
            '@type': 'ImageObject',
            'url': `${siteUrl}${data.logo.sharp.fluid.src}`
          }
          jsonLD = jsonLD.concat(ldJson)
        } else {
          jsonLD.push({
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: canonical,
            name: title,
            description: metaDescription
          })
        }

        return (
          <Helmet
            htmlAttributes={{lang}}
            title={title}
            titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
            link={
              canonical
                ? [{rel: 'canonical', key: canonical, href: canonical}]
                : []
            }
            meta={[
              // {name: 'google-site-verification', content: ''},
              {name: 'title', content: title},
              {name: 'description', content: metaDescription},

              {name: 'twitter:card', content: 'summary_large_image'},
              {name: 'twitter:site', content: '@'},
              {name: 'twitter:site:id', content: ''},
              {name: 'twitter:creator', content: siteAuthor},
              {name: 'twitter:title', content: title},
              {name: 'twitter:description', content: metaDescription},
              {name: 'twitter:image', content: metaImage},

              // {property: 'fb:app_id', content: '247158839784225'},
              {property: 'og:site_name', content: siteTitle},
              {property: 'og:type', content: type},
              {property: 'og:title', title},
              {property: 'og:description', content: metaDescription},
              {property: 'og:image', content: metaImage},
              {property: 'og:image:width', content: '1200'},
              {property: 'og:image:height', content: `${Math.floor((9 / 16) * 1200)}`},
              ...(canonical ? [{property: 'og:url', content: canonical}] : [])
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', ')
                  }
                  : []
              )
              .concat(meta)}>
            {
              amp &&
              <link rel='amphtml' href={amp} />
            }
            <script type='application/ld+json'>
              {JSON.stringify(jsonLD)}
            </script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: {eq: "siteSettings"}) {
      title
      description
      keywords
      author {
        name
      }
    }
    logo: file(relativePath: {eq: "logo.png"}) {
      sharp: childImageSharp {
        fluid(maxWidth: 86, maxHeight: 32) {
          src
        }
      }
    }
  }
`
